const ReadMoreToggle = (() => {
    function readMoreToggle() {
        let readMoreWrapper = $('.js-readmore-trigger');
        readMoreWrapper.each(function() {
            let readMoreBtn = $(this).find('a');
            $(readMoreBtn).on('click', () => {
                //toggle line clamp
                $(readMoreBtn).parent().toggleClass('collapsed');

                if ($(readMoreBtn).parent().hasClass('collapsed')) {
                    $(readMoreBtn).attr('aria-pressed', 'false');
                } else {
                    $(readMoreBtn).attr('aria-pressed', 'true');
                }
            });
        });
    }

    return {
        init() {
            readMoreToggle();
        },
    };
})();

export default (() => {
    $(document).ready(ReadMoreToggle.init);
})();
